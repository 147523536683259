@import "src/styles/variables";

.ContactUs {
  background-color: $left_background_color;
  color: $left_text_color;
  font-family: StudioFeixenSans-Medium, sans-serif;
  margin: 60px 30px 30px 30px;

  .details {
    margin-bottom: 30px;

    * {
      margin: 0;
      font-size: 25px;
    }
  }

  .work-inquiry-mobile {
    display: none;
  }

  .people-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 30px 0 30px 0;

    * {
      font-size: 25px;
    }

    .email {
      margin-top: 30px;
      font-size: 25px;
    }
  }

  .ContactUsSocials {
    margin: 30px 0;
    a {
      text-decoration: underline;
    }
    * {
      font-size: 25px;
    }
  }

  .special-thanks {
    margin: 30px 0 0 0;

    h6 {
      font-size: 16px;
      margin: 0;
      font-weight: normal;
    }
  }
}

@media (max-width: $mobile_screen_size) {
  .ContactUs {
    padding-bottom: 30px;
    margin: 0 10px 0 10px;

    * {
      font-size: 20px;
    }

    .details {
      margin: 30px 0 30px 0;
      * {
        font-size: 20px;
      }
    }

    .work-inquiry-mobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      h1 {
        margin: 0;
      }
    }

    .work-inquiry-desktop {
      display: none;
    }

    .people-wrapper {
      display: grid;
      grid-template-columns: none;
      grid-template-rows: 1fr 1fr;
      margin: 60px 0;

      .email {
        margin-top: 0;
        font-size: 20px;
      }

      .hajnalka {
        margin: 30px 0 0 0;
      }
      * {
        font-size: 20px;
      }
    }

    .ContactUsSocials {
      margin: 60px 0;
      * {
        font-size: 20px;
      }
    }

    .special-thanks {
      margin-top: 30px;
      * {
        font-size: 16px;
      }
    }
  }
}