@import "src/styles/variables";

.AboutUsDesktop, .AboutUsMobile {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 40px;
  background-color: $right_background_color;
  color: $right_text_color;
  font-family: StudioFeixenSans-Medium, sans-serif;

  .about-et-alier {
    margin: 0 30px;
    font-size: 25px;

    h3 {
      font-size: 30px;
    }

    * {
      margin: 20px 0;
    }
  }

  .item {
    margin: 15px 0;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .first {
    margin-bottom: 15px;
    margin-top: 0;
  }

  .small {
    font-size: 20px;
  }

  .people {
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 30px;
    font-size: 25px;
    gap: 20px;

    .image {
      height: 100%;
      width: 100%;
    }
  }

  .name {
    font-size: 30px;
  }

  .arrow {
    margin: 0 0 30px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 30px 30px 30px;
    font-size: 20px;
    gap: 30px;

    h3 {
      margin: 60px 0 30px 0;
      font-size: 20px;
    }
  }
}

.AboutUsMobile {
  margin-top: 0;
  padding-bottom: 0;
  .story {
    margin: 30px 10px;

    * {
      margin: 10px 0;
      font-size: 20px;
    }

    h3 {
      font-size: 24px;
    }
  }

  .about-me {
    font-size: 24px;
  }

  .item {
    margin: 0;
  }

  .people {
    display: flex;
    flex-direction: column;
    margin: 30px 10px;
    font-size: 24px;

    .hajnalka, .sarolta {
      margin: 0;
      display: flex;
      flex-direction: column;

      .name {
        font-size: 28px;
        margin: 30px 0;
      }

      .item {
        margin: 10px 0;
      }

      .misc {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        *, h3 {
          font-size: 20px;
        }

        .cursor-pointer {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}