@import"../../styles/variables";

.FilterBlock {
  margin-bottom: 60px;

  * {
    color: $full9grey !important;
    font-size: 25px;
  }
}

@media (max-width: $mobile_screen_size) {
  .FilterBlock {
    margin: 0;

    * {
      font-size: 24px;
    }
  }
}

