@import "../../styles/variables";
@import "../../fonts/fonts.scss";

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}


img {
  pointer-events: none;
}

a {
  text-decoration: inherit;
  color: inherit;
}

.MainPage {

  .mobile-right{
    background: $right_background_color;
    color: $right_text_color;
  }

  .MainPageLogo {
    margin-bottom: 35px;
  }

  .split {
    display: flex;
    flex-basis: 100%;
    overflow: hidden;
    height: 100vh;
    margin-top: -100px;
    padding-top: 100px;
    position: relative;
    width: 100%;
    backface-visibility: hidden;
    will-change: overflow;

    .left, .right {
      flex: 1;
      overflow: auto;
      height: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none;

      display: flex;
      flex-direction: column;
    }

    .left {
      background-color: $left_background_color;
      color: $left_text_color;
      cursor: url('../../../public/cursor_black-01-smaller.png'), auto;
      * {
        cursor: url('../../../public/cursor_black-01-smaller.png'), auto;
      }

      .content.contact {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    .right {
      background-color: $right_background_color;
      color: $right_text_color;
      cursor: url('../../../public/cursor_white-01-smaller.png'), auto;
      * {
        cursor: url('../../../public/cursor_white-01-smaller.png'), auto;
      }
    }

    .left::-webkit-scrollbar,
    .right::-webkit-scrollbar {
      display: none;
    }
  }

  .content {
    padding-top: 150px;
  }

  .MainPageSide {
    padding: 30px 0 0 0;
  }
}

@media (max-width: $mobile_screen_size) {
  .MainPage {
    .content {
      padding-top: 0;
    }
    .MainPageSide {
      // 20, because ProjectBlock already has a 10px padding
      padding-bottom: 20px;
      padding-top: 0;
    }
  }
}
