
$left_background_color: #ffffff;
$right_background_color: #262324;

$left_text_color: #262324;
$right_text_color: #ffffff;

$grey: #a8a7a7;
$full9grey: #999999;

$mobile_screen_size: 1008px;

:export {
  left_background_color: $left_background_color;
  right_background_color: $right_background_color;

  left_text_color: $left_text_color;
  right_text_color: $right_text_color;

  grey: $grey;
  full9grey: $full9grey;

  mobile_screen_size: $mobile_screen_size;
}