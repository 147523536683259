@import "src/styles/variables";

.RightFooter, .LeftFooter {
  margin: 0 30px;
  border-top: 2px solid $right_text_color;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100px;
  flex-shrink: 0;

  * {
    font-size: 20px;
    font-family: StudioFeixenSans-Medium, sans-serif;
  }

  .all-rights-reserved {
    display: flex;
    align-items: center;
    margin-left: 25px;
  }

  .socials {
    display: flex;
    align-items: center;
    margin-left: 25px;
  }

  .work-inquiry {
    display: flex;
    align-items: center;
  }
}

@media (max-width: $mobile_screen_size) {
  .RightFooter, .LeftFooter {
    margin: 0 10px 30px 10px;
    gap: 30px;
    * {
      font-size: 16px;
    }

    .socials, .all-rights-reserved {
      margin: 0;
    }
  }
}