@import "src/styles/variables";

.FooterSocials {
  .mobile-links {
    display: none;
  }
}

@media (max-width: $mobile_screen_size) {
  .FooterSocials {
    .desktop-links {
      display: none;
    }
    .mobile-links {
      display: flex;
      flex-wrap: wrap;
      .link {
        padding-right: 5px;
      }
    }
  }
}