@import "src/styles/variables";

.PhoneModal {
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 100;
  font-family: StudioFeixenSans-Medium, sans-serif;

  display: grid !important;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;

  h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
  }

  .x {
    font-size: 30px;
    font-weight: 500;
  }
}

.PhoneModal.left {
  background-color: $left_background_color;
}

.PhoneModal.right {
  background-color: $right_background_color;
  color: $right_text_color;
}
