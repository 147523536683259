@import"../../styles/variables";

.ProjectPage {

  * {
    font-family: StudioFeixenSans-Medium, sans-serif;
  }

  .mobile {
    display: none;
    padding: 0 10px;

    .title {
      font-size: 28px;
      margin-left: 0;
    }

    .paragraph {
      ul {
        list-style-type: none;
        padding: 0;
        font-size: 24px;
      }
      p {
        font-size: 24px;
      }
      h1 {
        font-size: 28px;
      }
    }

    .carousel {
      margin-top: 10px;
      z-index: 0;
      * {
        z-index: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .ProjectInfoBlock {
      margin: 0;
      h3, * {
        font-size: 16px;
      }
    }
  }

  .split {

    .content {
      margin: 0 30px;
    }

    display: flex;
    flex-basis: 100%;
    overflow: hidden;
    height: 100vh;
    margin-top: -100px;
    padding-top: 100px;
    position: relative;
    width: 100%;
    backface-visibility: hidden;
    will-change: overflow;

    .left, .right {
      flex: 1;
      overflow: auto;
      height: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .right {
      .ProjectPageHeader {
        flex-shrink: 0;
      }
    }
  }

  .left::-webkit-scrollbar,
  .right::-webkit-scrollbar {
    display: none;
  }

  .title {
    font-size: 35px;
    margin-top: 22px;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .title.out_of_the_box {
    font-family: StudioFeixenSerif-Medium, serif;
  }

  .title.inside_the_box {
    font-family: StudioFeixenSans-Bold, sans-serif;
  }


  .left {
    left: 0;

    .content {
      margin-right: 0;
    }

    .filter-block {
      margin: 30px 30px 100px 30px;
    }

    .project-title {
      margin: 30px;
    }

    .image-wrapper {
      margin: 30px 0;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .right {
    right: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    .project-info-block {
      margin: 30px 30px 50px 30px;
    }

    .paragraph {
      * {
        margin-left: 0 !important;
      }
      p:first-child {
        margin-top: 0;
      }
      p {
        margin: 20px 30px;
        font-size: 25px;
      }

      ul {
        font-size: 25px;
        list-style-type: none;
        padding: 0;
        margin-left: 30px;
      }

      a {
        text-decoration: underline;
      }
      h1 {
        font-size: 35px;
      }
    }
  }

}

.ProjectPage.itb {
  * {
    background-color: $left_background_color;
    color: $left_text_color;
    cursor: url('../../../public/cursor_black-01-smaller.png'), auto;
  }
}

.ProjectPage.otb {
  * {
    background-color: $right_background_color;
    color: $right_text_color;
    cursor: url('../../../public/cursor_white-01-smaller.png'), auto;
  }
}

@media (max-width: $mobile_screen_size) {
  .ProjectPage {
    .desktop {
      display: none;
    }
    .mobile {
      display: inherit;
      padding: 0;

      .mobile-content {
        padding: 0 10px;
      }
    }
  }
}