@import "src/styles/variables";

html, body, #root {
  height: 100%;
}

.FourOFourPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  color: $left_text_color;

  .MainPageMobileHeader {
    display: none;
  }

  .buffer {
    display: none;
  }

  .left {
    cursor: url('../../../public/cursor_black-01-smaller.png'), auto;
    * {
      cursor: url('../../../public/cursor_black-01-smaller.png'), auto;
    }
    .content-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        margin-top: 320px;
        text-align: center;
        * {
          margin: 0;
        }

        h1 {
          font-size: 140px;
          font-weight: normal;
          font-family: StudioFeixenSerif-Medium, serif;
        }
        h2 {
          font-size: 70px;
          font-weight: normal;
          font-family: StudioFeixenSans-Medium, sans-serif;
          margin: 45px 0 30px 0;
        }
        h3 {
          font-size: 35px;
          font-weight: normal;
          font-family: StudioFeixenSans-Medium, sans-serif;
        }
        .arrow {
          margin-top: 50px;
        }
        a {
          font-size: 35px;
        }
      }
    }
  }

  .right {
    background-color: $right_background_color;
    color: $right_text_color;
    cursor: url('../../../public/cursor_white-01-smaller.png'), auto;
    * {
      cursor: url('../../../public/cursor_white-01-smaller.png'), auto;
    }

    .content-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        margin-top: 350px;
        width: 40%;

        .floating {
          animation-name: floating;
          animation-duration: 3s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
        }

        @keyframes floating {
          0% { transform: translate(0,  0px); }
          50%  { transform: translate(0, 30px); }
          100%   { transform: translate(0, -0px); }
        }
      }
    }
  }
}

@media (max-width: $mobile_screen_size) {
  .FourOFourPage {
    display: flex;
    flex-direction: column;
    .MainPageHeader {
      display: none;
    }

    .MainPageMobileHeader {
      display: block;
      position: static;
      .right {
        padding: 0;
      }
    }

    .left {
      .content-wrapper {
        display: block;
        .content {
          margin: 30px 0 0 0;
          text-align: center;
          * {
            margin: 0;
          }

          h1 {
            font-size: 80px;
            font-weight: normal;
            font-family: StudioFeixenSerif-Medium, serif;
          }
          h2 {
            font-size: 30px;
            font-weight: normal;
            font-family: StudioFeixenSans-Medium, sans-serif;
            margin: 10px 0;
          }
          h3 {
            font-size: 20px;
            font-weight: normal;
            font-family: StudioFeixenSans-Medium, sans-serif;
          }
          .arrow {
            margin-top: 40px;
            margin-bottom: 10px;
          }
          a {
            font-size: 28px;
          }
        }
      }
    }

    .right {
      padding-bottom: 50px;
      height: 100%;
      background-color: $left_background_color;
      .content-wrapper {
        height: 100%;
        .content {
          height: 100%;
          margin: 0;
        }
      }
    }
  }
}