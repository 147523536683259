@import "src/styles/variables";
@import "src/fonts/fonts";

.MainPageHeader {
  width: 100%;
  height: 100px;
  flex-shrink: 0;

  .header-left, .header-right {

    background: transparent;
    height: 100px;
    width: calc(50% - 60px);
    position: fixed;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 30px;

    color: $grey;
    font-family: StudioFeixenSans-Medium, serif;

    h3 {
      text-align: center;
      margin: 0;
      font-weight: normal !important;
      font-size: 20px;
    }

    .circle {
      text-align: center;
      margin: 5px 0 0 0;
      font-weight: normal !important;
    }

    .start{
      justify-self: flex-start;
      align-self: center;
    }

    .mid {
      justify-self: center;
      align-self: center;
    }

    .end {
      justify-self: flex-end;
      align-self: center;
    }
  }

  .et, .alier {
    position: absolute;
    top: 100px;
    transition: 0.5s;

    #et, #alier {
      transition: 0.5s;
    }

    #alier {
      height: 150px;
      width: 500px;
      margin-right: -42px;
    }

    #et {
      height: 150px;
      width: 360px;
      margin-right: -100px;
      margin-left: -30px;
    }
  }

  .alier {
    right: 35px;
  }

}