@import "src/styles/variables";
@import "src/fonts/fonts";

.ProjectPageHeader {
  width: 100%;
  height: 100px;

  * {
    background-color: transparent !important;
    font-weight: normal !important;
  }

  .header-left, .header-right {

    background: transparent;
    height: 100px;
    width: calc(50% - 60px);
    position: fixed;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 30px;

    font-family: StudioFeixenSans-Bold, serif;

    * {
      color: $grey !important;
    }

    h3, h5 {
      text-align: center;
      margin: 0;
      font-size: 20px;
    }


    .circle {
      text-align: center;
      margin: 5px 0 0 0;
    }

    .start{
      justify-self: flex-start;
      align-self: center;
    }

    .mid {
      justify-self: center;
      align-self: center;
    }

    .end {
      justify-self: flex-end;
      align-self: center;
    }
    
    .start, .end {
      img {
        pointer-events: all !important;
        cursor: pointer !important;
      }
    }
  }
}