@import "../../styles/variables";

.ProjectBlock {
    padding: 30px;

    img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: $mobile_screen_size) {
    .ProjectBlock {
        padding: 10px;

        .project-title {
            display: none;
        }
    }
}