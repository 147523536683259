
.project-title {
    margin-top: 25px;
    margin-bottom: 30px;
    text-align: left;

    .sans {
        font-family: StudioFeixenSans-Bold, sans-serif;
        font-size: 35px;
    }

    .serif {
        font-family: StudioFeixenSerif-Medium, serif;
        font-size: 35px;
    }
}