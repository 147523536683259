
@font-face {
    font-family: "StudioFeixenSans-Bold";
    src: 
        url("./StudioFeixenSans-Bold.woff") format("woff"),
        url("./StudioFeixenSans-Bold.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "StudioFeixenSans-Medium";
    src: 
        url("./StudioFeixenSans-Medium.woff") format("woff"),
        url("./StudioFeixenSans-Medium.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "StudioFeixenSerif-Bold";
    src: 
        url("./StudioFeixenSerif-Bold.woff") format("woff"),
        url("./StudioFeixenSerif-Bold.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "StudioFeixenSerif-Medium";
    src: 
        url("./StudioFeixenSerif-Medium.woff") format("woff"),
        url("./StudioFeixenSerif-Medium.woff2") format("woff2");
    font-display: swap;
}
