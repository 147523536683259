@import "src/styles/variables";

.ProjectInfoBlock {
    margin: 30px 0;
    h3 {
        margin: 0;
        font-size: 16px;
    }
    * {
        font-size: 16px;
    }

    .slash {
        margin-bottom: 15px;
    }
}

@media (max-width: $mobile_screen_size) {
    .ProjectInfoBlock {
        padding-bottom: 30px;
    }
}