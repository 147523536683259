

.buffer {
  height: 85px;
}

.MainPageMobileHeader {

  z-index: 5;
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 10px;
  }

  .right {
    justify-self: flex-end;
  }

  img {
    height: 60px;
    width: auto;
  }
}